exports.components = {
  "component---src-components-templates-category-index-tsx": () => import("./../../../src/components/templates/category/index.tsx" /* webpackChunkName: "component---src-components-templates-category-index-tsx" */),
  "component---src-components-templates-product-index-tsx": () => import("./../../../src/components/templates/product/index.tsx" /* webpackChunkName: "component---src-components-templates-product-index-tsx" */),
  "component---src-components-templates-shop-index-tsx": () => import("./../../../src/components/templates/shop/index.tsx" /* webpackChunkName: "component---src-components-templates-shop-index-tsx" */),
  "component---src-components-templates-store-page-index-tsx": () => import("./../../../src/components/templates/store-page/index.tsx" /* webpackChunkName: "component---src-components-templates-store-page-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-checkout-index-tsx": () => import("./../../../src/pages/cart/checkout/index.tsx" /* webpackChunkName: "component---src-pages-cart-checkout-index-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

